import CardFindForm from 'models/card/components/CardFindForm'
import { Page } from 'layout'

import './styles.css'


const ClubCardFindPage = () => {
  return (
    <Page className="loginPage" hideNav={true} isFixed={true}>
      <div className="loginPanel">
        <CardFindForm />
      </div>
    </Page>
  );
};

export default ClubCardFindPage;
