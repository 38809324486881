import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux'

import useLocalStorage from "utils/localStorageHook"
import { useFindCardMutation } from 'models/card/api'
import Card from "models/card/components/Card"
import { Form, TextField } from "components/forms"
import Button from "components/Button"

import "./styles.css"


const CardFindForm = ({ initials }) => {
  const { control, handleSubmit, reset, formState, setValue, setError, clearErrors } = useForm();
  const user = useSelector(state => state.auth.user);
  const [findCard, findCardProps] = useFindCardMutation();

  const [findedCards, setFindedCards] = useLocalStorage("findedCards", 3600*24*7); // cached for 7 days

  const onSubmit = data => {
    if (!findCardProps.isLoading) {
      if (!data?.phone) {
        data.phone = user?.phone;
      }
      findCard(data);
    }
  }

  const resetStatus = () => {
    reset();
    findCardProps.reset();
    setFindedCards(null);
  }

  const onSuccess = () => {
    setFindedCards(findCardProps.data);
  }

  return (
    <Form
      initials={initials}
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: findCardProps.isLoading,
        isSuccess: findCardProps.isSuccess,
        isError: findCardProps.isError,
        errorData: findCardProps.error?.data,
        successData: findCardProps.data,
      }}
      formFooter={(status) => (
        (findedCards?.length || status.isSuccess) ? 
          (
            <Button title="Найти другую карту" size="lg" onClick={resetStatus} />
          ) 
          : 
          (
            <Button type="submit" title="Найти карту" size="lg" loading={status.isLoading} />
          )
      )}
    >
      <div className="formText">
        <h2>Клубная карта</h2>
      </div>
      {(findedCards?.length || findCardProps.isSuccess) ? 
        (
          <div>
            {(findedCards || findCardProps.data).map(card => {
              return (
                <Card key={`card${card.number}`} name={card.cardType?.name} code={card.cardType?.code} number={card.number} />
              )
            })}
          </div>
        ) 
        : 
        (
          <TextField
            name="phone"
            placeholder="Введите номер телефона"
            required={true}
          />
        )
      }
    </Form>
  );
};

export default CardFindForm;
