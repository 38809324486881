import { api } from 'redux/api/api'


const CardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardDetail: builder.query({
      query: () => ({
        url: `card/card/`,
      }),
    }),

    findCard: builder.mutation({
      query(formData) {
        return {
          url: 'card/card/find/',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export default CardApi;

const {
  useLazyGetCardDetailQuery,
  useFindCardMutation,
} = CardApi;

export {
  useLazyGetCardDetailQuery as useLazyGetDetailQuery,
  useFindCardMutation,
};
