import { useState } from "react"

const useLocalStorage = (key, expireDuration = null) => {
    const [localStorageValue, setLocalStorageValue] = useState(() => {
      try {
          let value = localStorage.getItem(key);
          if (value) {
            value = JSON.parse(value);
            if (expireDuration) {
              if (!value.timestamp) return;
              const diff = (new Date().getTime() - value.timestamp) / 1000;
              if (diff > expireDuration) return;
            }
            return value.value;
          }
      } catch (error) {}
    })

    const setLocalStorageStateValue = (newValue) => {
      localStorage.setItem(key, JSON.stringify({value: newValue, timestamp: new Date().getTime()}));
      setLocalStorageValue(newValue)
    }
    return [localStorageValue, setLocalStorageStateValue]
}

export default useLocalStorage;
